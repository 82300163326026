import React from 'react'
import { Grid } from '@pw/ui'

import PostBox from './Box'

const GridOfPosts = ({ rowSize = 4, posts, hideCategory = true, ...props }) => (
  <Grid
    templateColumns={{
      base: '1fr',
      sm: '1fr 1fr',
      md: `repeat(${rowSize}, 1fr)`,
    }}
    gap={6}
    {...props}
  >
    {posts.map((post) => (
      <PostBox
        displayType="vertical"
        post={post?.node || post}
        key={post?.node?.id || post.id}
        hideCategory={hideCategory}
      />
    ))}
  </Grid>
)

export default GridOfPosts
