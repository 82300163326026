import React from 'react'
import { graphql } from 'gatsby'
import startCase from 'lodash/startCase'
import { Heading, Link, Box } from '@pw/ui'

import { Layout, Container } from '../components/Layout'
import PostGrid from '../components/Posts/Grid'

const PostCategoryPageTemplate = ({ pageContext, data, location }) => {
  const { title = '', seo = {} } = data?.contentfulPostCategory || {}
  const { landingPage } = pageContext
  const posts = data?.allContentfulBlogPost?.edges || []

  const backLink = landingPage ? `/${landingPage}` : false

  return (
    <Layout
      headerTitle={`Category`}
      location={location}
      seo={{
        ...seo,
        title: `${title}`,
        description: `${title}`,
      }}
    >
      <Container pt="4" py="8" px={{ base: 0, md: 4 }} maxW="1200px">
        <Box pb="8">
          <Heading as="h2">{title}</Heading>

          {backLink && <Link to={backLink}>Go back to {startCase(landingPage)}</Link>}
        </Box>

        <PostGrid posts={posts} />
      </Container>
    </Layout>
  )
}

export default PostCategoryPageTemplate

export const pageQuery = graphql`
  query PostCategoryPage($slug: String!) {
    contentfulPostCategory(slug: { eq: $slug }) {
      title
    }

    allContentfulBlogPost(
      filter: { postCategories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }
  }
`
