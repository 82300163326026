import React from 'react'
import { Link } from '@pw/ui'

import ContentBox from '../ContentBox'

const PostBox = ({ post, hideCategory = false, displayType = 'default', ...props }) => {
  const squareThumb = post?.squareThumb?.fluid || false
  const verticalPhoto = post?.verticalThumb?.fluid || false
  const heroImage = post?.heroImage?.fluid || false
  const category = post?.category?.name || false
  const tag = !hideCategory && category

  const photo =
    displayType === 'vertical'
      ? verticalPhoto || squareThumb
      : squareThumb || verticalPhoto

  return (
    <ContentBox
      as={Link}
      to={`/${post.slug}`}
      photo={photo || heroImage}
      title={post.title}
      tag={tag}
      {...props}
    />
  )
}

export default PostBox
